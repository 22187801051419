<template>
  <!-- pricing plan cards -->
  <b-row class="pricing-card">
    <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
      <b-row class="d-flex align-items-center justify-content-center">
        <b-col md="4">
          <b-card align="center">
            <!-- img -->
            <!-- <b-img
              v-if="pricing.basicPlan.img"
              :src="pricing.basicPlan.img"
              class="mb-2 mt-5"
              alt="basic svg img"
            /> -->
            <!--/ img -->
            <h3>Free</h3>
            <b-card-text> A simple start for everyone </b-card-text>

            <!-- annual plan -->
            <div class="annual-plan">
              <div class="plan-price mt-2">
                <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                <span
                  class="pricing-basic-value font-weight-bolder text-primary"
                  >0</span
                >
                <sub
                  class="pricing-duration text-body font-medium-1 font-weight-bold"
                  >/month</sub
                >
              </div>
            </div>
            <!--/ annual plan -->
            <p class="text-left">
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-1"
                size="16"
              />Market Search
            </p>
            <p class="text-left">
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-1"
                size="16"
              />Account Search
            </p>
            <p class="text-left">
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-1"
                size="16"
              />Favorite Accounts
            </p>
            <p class="text-left">
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-1"
                size="16"
              />Favorite Apps
            </p>
            <p class="text-left">
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-1"
                size="16"
              />Keyword Suggest
            </p>
            <p class="text-left">
              <feather-icon icon="CheckCircleIcon" class="mr-1" size="16" />Top
              Charts
            </p>
            <p class="text-left">
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-1"
                size="16"
              />Track 10 Apps
            </p>
            <p class="text-left">
              <feather-icon icon="XCircleIcon" size="16" class="mr-1" />Keyword
              Search
            </p>
            <p class="text-left">
              <feather-icon icon="XCircleIcon" size="16" class="mr-1" />Keyword
              Max
            </p>
            <p class="text-left">
              <feather-icon icon="XCircleIcon" size="16" class="mr-1" />Niches
            </p>
            <p class="text-left">
              <feather-icon icon="XCircleIcon" size="16" class="mr-1" />AI
              Description Generator
            </p>
            <p class="text-left">
              <feather-icon icon="XCircleIcon" size="16" class="mr-1" />VIP
              Discord Group
            </p>
            <!-- buttons -->
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              block
              class="mt-2"
              variant="outline-success"
            >
              Your current plan
            </b-button>
          </b-card>
        </b-col>
        <b-col md="4" v-for="plan in plans" :key="plan.id">
          <b-card align="center">
            <!-- img -->
            <!-- <b-img
              v-if="pricing.basicPlan.img"
              :src="pricing.basicPlan.img"
              class="mb-2 mt-5"
              alt="basic svg img"
            /> -->
            <!--/ img -->
            <h3>{{ plan.name_plan }}</h3>
            <b-card-text>
              {{ plan.descreption_plan }}
            </b-card-text>

            <!-- annual plan -->
            <div class="annual-plan">
              <div class="plan-price mt-2">
                <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                <span
                  class="pricing-basic-value font-weight-bolder text-primary"
                  >{{ plan.price }}</span
                >
                <sub
                  class="pricing-duration text-body font-medium-1 font-weight-bold"
                  >/{{ plan.interval_type.toLowerCase() }}</sub
                >
              </div>
            </div>
            <!--/ annual plan -->

            <!-- plan benefit -->
            <p
              class="text-left"
              v-for="(feature, index) in JSON.parse(plan.features)"
              :key="index"
            >
              <feather-icon icon="CheckCircleIcon" size="16" class="mr-1" />
              {{ feature }}
            </p>

            <!--/ plan benefit -->

            <!-- buttons -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              class="mt-2"
              variant="primary"
              @click="createInvoice(plan.price,plan.id)"
            >
              Upgrade
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
  <!--/ pricing plan cards -->
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from "bootstrap-vue";
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import router from "@/router";
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    // AppCollapseItem,
    // AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: "monthly",
      monthlyPlanShow: true,
      pricing: {},
      plans: null,
    };
  },
  computed: {
    planCheck() {
      return this.$store.getters.getplanCheck;
    },
  },
  mounted() {
    this.getPlans();
    if (this.planCheck) {
      router.push({ name: "error-404" });
    }
  },
  methods: {
    tooglePlan() {
      if (this.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
    async getPlans() {
      await axios
        .get("/list-plans")
        .then(({ data }) => {
          this.plans = data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    createInvoice(price,id) {
      axios
        .post("/invoices", { amount_total: price })
        .then(({ data }) => {
          this.$router.push({
            path: "/pages/payment-method",
            query: { same_id: data.invoice_id, plan_id: id },
          });
        })
        .catch(console.error);
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
